import React from 'react';
import { Box, Text } from 'grommet';

import { Layout } from '../layout';
import SEO from '../seo';
import { StyledLink } from './styled';

export const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Box
      height='74vh'
      gap='medium'
      pad='medium'
    >
      <h1>NOT FOUND</h1>
      <Box gap='small'>
        <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
        <StyledLink to='/'>Take me to home!</StyledLink>
      </Box>
    </Box>
  </Layout>
);

export default NotFoundPage;
